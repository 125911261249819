<template>
    <div>

        <!-- TOOLBAR -->
        <v-app-bar color="primary" prominent>
            <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            
            <v-toolbar-title>
                <img class="mr-5"  :src="require('@/assets/logo_white.png')" height="30" :aspect-ratio="16/9"></img>
                
               <small class="text-overline">Reportes y Certificados</small> </v-toolbar-title>

            <v-spacer></v-spacer>

            <div class="d-flex flex-column "> <small class="text-button"> {{ user.name }}</small>
             <small class="text-overline mt-n4 font-weight-thin">   {{ user.rol.description }}</small></div>

            <v-btn variant="text" icon="mdi-bell"></v-btn>
        </v-app-bar>

        <!-- DRAWER -->
        <v-navigation-drawer class="fill-height" v-model="drawer" location="left" temporary style="text-align: center !important;  background-color: #000;">
            <v-list-item class="my-5"   style="text-align: center !important;  background-color: #000;" nav>
                <v-img :src="require('@/assets/images/m.png')" height="50" :aspect-ratio="16/9"></v-img>
            </v-list-item>

            <v-divider color="white"></v-divider>
            <!-- <v-list :items="items"></v-list> -->
            <v-list bg-color="black"  nav>
                <v-list-subheader>REPORTS</v-list-subheader>

                <v-list-item v-for="(item, i) in items" :key="i" :value="item" color="gold-midas" rounded="xl"
                    :to="item.value">
                    <template v-slot:prepend>
                        <v-icon :icon="item.icon"></v-icon>
                    </template>

                    <v-list-item-title class="text-left">{{item.title}}</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider color="white"></v-divider>
             <v-list 
                density="compact"
                nav>
                <v-list-item  to="/logout">
                    <template v-slot:prepend>
                        <v-icon color="white" icon="mdi mdi-location-exit"></v-icon>
                    </template>
                    <v-list-item-title  class="text-left menu-text">CERRAR SESIÓN</v-list-item-title>
                </v-list-item>
                </v-list>
        </v-navigation-drawer>

        <!-- MAIN ROUTER-VIEW -->
        <v-main>
            <v-breadcrumbs :items="urls">
                <template v-slot:prepend>
                    <v-icon size="small">mdi mdi-home</v-icon>
                </template>
            </v-breadcrumbs>
            <v-fade-transition>
                <router-view />
            </v-fade-transition>
        </v-main>
    </div>
</template> 
<script>
const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;
import { useCommonStore } from "@/stores/commons.js";
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: "REPORTES",
        value: "reports",
        icon: "mdi mdi-file-document-multiple",
      },
      {
        title: "FABRICACIÓN",
        value: "manufacturing",
        icon: "mdi mdi-gantry-crane",
      },
    ],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
     user() {
      let u = JSON.parse(localStorage.getItem(NAME_APP));
      return u && u.user ? u.user : {};
    },
    urls() {
      const store = useCommonStore();
      return store.items;
    },
  },
//   methods:{
//     closeSesion(){

//     }
//   }
};
</script>
<style scoped>
.menu-text{
    color: #fff !important;
}
</style>
