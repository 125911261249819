<template>
  <v-app class="app-main">
    <v-overlay v-model="loginShowOverlay">

    </v-overlay>
    <v-overlay v-model="showOverlay" class="align-center justify-center flex-column text-center">
      <div>
        <img :src="require('@/assets/anim/spinner.gif')" id="image">
      </div>
      <div class="spinner-text">
        <span >Espere un momento..</span>
      </div>
    </v-overlay>
    <router-view />
  </v-app>
</template>
<script>
import { useCommonStore } from '@/stores/commons'
import { useAccountStore } from '@/stores/accounts'
export default {
  computed: {
    showOverlay() {
      const store = useCommonStore();
      return store.isBussy
    },
    loginShowOverlay() {
      const lgStore = useAccountStore();
      return lgStore.isBussy
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import '@/assets/css/spinner.scss';

.app-main {
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: #fff;
  color: #2c3e50;
}

.midas-spinner-container {
  height: 100vh;
  width: 100%;
}

.slogan {
  font-family: "Poppins" !important;
  font-size: 50px;
  font-weight: 700 !important;
  color: #222831;
}

.txt-gold {
  color: #f0a500;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-data-table-header__content {
    color: #f0a500 !important;
    font-weight: 700 !important;
}

body {
  scrollbar-width: thin; /* "auto" o "thin" */
  scrollbar-color: #2c3e50 #222831; /* Color del pulgar y la pista */
}

/* Estilos específicos para WebKit (Chrome, Edge, Safari) */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #222831;
}

body::-webkit-scrollbar-thumb {
  background-color: #2c3e50;
  border-radius: 20px;
  border: 3px solid #222831;
}

</style>
