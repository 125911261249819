import { defineStore } from 'pinia'
import repositoryService from '@/stores/services/repository.services'
const PREFIX_CONFIG = 'config/'

export const useCommonStore = defineStore("commons", {
    state: () => ({
        items: [],
        isBussy: false,
        consecutives: [],
        oProductionNumber: {}, //ORDEN DE PEDIDO DE FABRICACION
        fabNumber: {},  // CONSECUTICO DE CERTIFICADO DE FABRICACION
        serialNumber: {},  //NUMERO DE SERIE PARA FABRICACION
    }),
    getters: {
        getItems: (state) => state.items,
    },
    actions: {
        setItems(items) {
            this.items = items;
        },
        setLoader(mode){
            this.isBussy = mode
        },
        getConsecutive(){
            try {
                let entity = `${PREFIX_CONFIG}consecutives`
                return repositoryService.get(entity).then(
                    (response) => {
                        this.consecutives = response.data.data
                        return Promise.resolve(response.status)
                    },
                    () => {
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
        updateConsecutive(data){
            try {
                let entity = `${PREFIX_CONFIG}consecutives`
                return repositoryService.update(data, entity).then(
                    (response) => {
                        return Promise.resolve(response.status)
                    },
                    () => {
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        }
    },
})