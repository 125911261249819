import { defineStore } from 'pinia'
import authService from '@/stores/services/auth.services'
import repositoryService from '@/stores/services/repository.services'
import { getDevice } from '@/utils/session'

const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;
const PREFIX = 'midas_auth/'

const user_data = JSON.parse(localStorage.getItem(NAME_APP));


export const useAccountStore = defineStore("account", {
    state: () => ({
        userAccount: user_data && user_data.user ? user_data.user : undefined,
        usersList: [],
        rolesList: [],
        isBussy: false
    }),
    getters: {
        account: (state) => state.userAccount,
        users: (state) => state.usersList,
        roles: (state) => state.rolesList,
    },
    actions: {
        async login(credentials) {
            try {
                this.isBussy = true
                let entity = `${PREFIX}login`
                credentials.session = await getDevice()
                return authService.login(credentials, entity).then(
                    response => {
                        this.userAccount = { name: response.data.user, email: response.data.email, rol: response.data.rol, uakey: response.data.kuidd }
                            localStorage.setItem(NAME_APP, JSON.stringify({
                                user: this.userAccount,
                                access_token: response.data.access_token,
                                expires_in: response.data.expires_in,
                                token_type: "bearer"
                            }));
                            setTimeout(() => this.isBussy =  false, 1200);
                        return Promise.resolve(response.status);
                    },
                    () => {
                        setTimeout(() => this.isBussy =  false, 1200);
                        return Promise.reject(false);
                    }
                );
            } catch (error) {
                setTimeout(() => this.isBussy =  false, 1200);
                console.error(error);
                throw error;
            }
        },
        validateToken(){
            try {
                let entity = `${PREFIX}me`
                return repositoryService.post({me: ""},entity).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    () => {
                        this.usersList = []
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                this.userData = {}
                console.error(error);
            }
        },
        logout() {
            localStorage.removeItem(NAME_APP);
            this.userAccount = null,
                this.userData = {}
        },
        create(data) {
            try {
                let entity = `${PREFIX}register`
                return authService.create(data, entity).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    (e) => {
                        console.error(e)
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                this.userData = {}
                console.error(error);
            }
        },
        getUsers() {
            try {
                let entity = `${PREFIX}user`
                return repositoryService.get(entity).then(
                    response => {
                        this.usersList = response.data.data
                        return Promise.resolve(response.status)
                    },
                    () => {
                        this.usersList = []
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                this.userData = {}
                console.error(error);
            }
        },
        getRoles() {
            try {
                let entity = `${PREFIX}user/roles`
                return repositoryService.get(entity).then(
                    response => {
                        this.rolesList = response.data.data
                        return Promise.resolve(response.status)
                    },
                    () => {
                        this.rolesList = []
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                this.userData = {}
                console.error(error);
            }
        },
        delete(item) {
            try {
                let entity = `${PREFIX}user`
                return repositoryService.delete(item, entity).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    () => {
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                this.userData = {}
                console.error(error);
            }
        }
    },
})