import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import interceptor from './utils/interceptor';
import { createPinia } from 'pinia'
import { useCommonStore } from '@/stores/commons'
loadFonts()

const pinia = createPinia()
createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .mount('#app')

const store = useCommonStore();
interceptor(store, router)
