import { createRouter, createWebHistory } from 'vue-router'
// import NotFoundView from '@/views/NotFoundView'
import { isAuthenticated } from '@/utils/validate'
import HomeView from '../views/Layout/index.vue'
import { useCommonStore } from '@/stores/commons.js'

const routes = [
  {
    path: '/',
    name: 'Midas',
    component: HomeView,
    children: [
      {
        path: '',
        meta: { requiresAuth: true },
        name: 'Inicio',
        component: () => import('../views/Home/index.vue')
      },
      {
        path: 'reports',
        meta: { requiresAuth: true },
        name: 'Reportes',
        component: () => import('../views/Reports/index.vue'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Ordenes',
            component: () => import('../views/Reports/ListView.vue')
          },
          {
            path: 'consulta/:uuid',
            meta: { requiresAuth: true },
            name: 'Consulta Reporte',
            props: true,
            component: () => import('../views/Reports/ReadEditView.vue')
          }
        ]
      },
      {        
        path: 'manufacturing',
        meta: { requiresAuth: true },
        name: 'Manufactura',
        component: () => import('../views/Manufacturing/index.vue'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Pedidos',
            component: () => import('../views/Manufacturing/ListView.vue')
          },
          {
            path: 'consulta/:uuid',
            meta: { requiresAuth: true },
            name: 'Consulta Certificado Fabricacion',
            props: true,
            component: () => import('../views/Manufacturing/ReadEditView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Account/login.vue'),
    name: 'Sesión',
    meta: { title: 'Autenticacion', icon: 'mdiAccounts', requiresAuth: false }
  },
  {
    path: '/logout',
    component: () => import('@/views/Account/logout.vue'),
    name: 'Salir',
    meta: { title: 'Cerrando sesión', icon: 'mdiAccounts', requiresAuth: false }
  },
  {
    path: '/forgot_password',
    component: () => import('@/views/Account/forgot_password.vue'),
    name: 'Olvide Contraseña',
    meta: { title: 'Autenticacion', icon: 'mdiAccounts', requiresAuth: false }
  },
]

const router = createRouter({
  scrollBehavior: function () {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const store = useCommonStore();
  const items = to.matched.map(route => ({
    title: route.name,
    href: route.path,
  }));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
   if (to.path != '/login' && isAuthenticated()) {
      store.setItems(items);
      next();
    }
    else {
      router.replace({ path: '/login' })
    }
  }
  else {
    store.setItems(items);
    next()
  }
})

export default router
