const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;

import { useAccountStore } from '@/stores/accounts'

export function isAuthenticated() {
  let loggedIn = JSON.parse(localStorage.getItem(NAME_APP));
  if (loggedIn === null) {
    return false
  }
  else {
    // const store = useAccountStore();
    // store.validateToken().then((rs) => {
    //   console.log("validate token", rs)
    // }, (e) => {
    //   console.log("error validate token", e)
    // })
    return true
  }
}

export function isAuthorized(roles) {
  let U = JSON.parse(localStorage.getItem(NAME_APP));
  let rol = null;
  rol = U ? U.user.rol : () => { return false }
  return roles.includes('*') ? true : roles.includes(rol.name);
}